<template>
  <div>
    <b-table-simple responsive>
      <thead>
      <tr class="tr-header">
        <th v-for="(header, index) in headers" :key="index">
          {{ header }}
        </th>
      </tr>
      </thead>

      <tbody v-for="exam in examsList" :key="exam.id">
      <tr class="tr-tbody">
        <td>
          <div>
            {{ moment(exam?.start_datetime).format('HH[h]mm') }}
          </div>
          <div class="exam-date">
            {{ moment(exam?.start_datetime).format('DD/MM/YYYY') }}
          </div>
        </td>

        <td>
          <div class="patient-container">
            <PatientInformation
              :appointment="exam"
              :status="getExamsStatus(exam)"
            />
            <Export
              v-can="'FpPac1'"
              @click="goPatient(exam?.patient?.id)"
              class="export"
            />
          </div>
        </td>
        <td>
          <Ellipsis>
            {{ exam?.professional?.name ?? '-' }}
          </Ellipsis>
        </td>
        <td>
          <Ellipsis>
            {{ exam?.room?.name ?? '-' }}
          </Ellipsis>
        </td>
        <td
          class="procedure-column pending"
          :class="{
            'not-finished-exams': getExamsStatus(exam) !== 'ALL_EXAMS_FINISHED'
          }"
        >
          <div v-if="getExamsStatus(exam) !== 'ALL_EXAMS_FINISHED'">
            <div>
              {{
                textualExamCounting(
                  getAppointmentItemsCountingByStatus(exam, 'PENDING', 'EXAM')
                )
              }}
            </div>
            <div>
              {{
                textualProcedureCounting(
                  getAppointmentItemsCountingByStatus(
                    exam,
                    'OPENED',
                    'PROCEDURE'
                  )
                )
              }}
            </div>
          </div>
          <span v-else>0</span>
        </td>
        <td
          class="procedure-column finished"
          :class="{
            'not-finished-exams': getExamsStatus(exam) !== 'ALL_EXAMS_FINISHED'
          }"
        >
          <div
            v-if="
              getAppointmentItemsCountingByStatus(exam, 'FINISHED', 'EXAM') >
                0 ||
              getAppointmentItemsCountingByStatus(
                exam,
                'FINISHED',
                'PROCEDURE'
              ) > 0
            "
          >
            <div>
              {{
                textualExamCounting(
                  getAppointmentItemsCountingByStatus(exam, 'FINISHED', 'EXAM')
                )
              }}
            </div>
            <div>
              {{
                textualProcedureCounting(
                  getAppointmentItemsCountingByStatus(
                    exam,
                    'FINISHED',
                    'PROCEDURE'
                  )
                )
              }}
            </div>
          </div>
          <span v-else> 0 </span>
        </td>

        <td class="text-right p-3">
          <b-button
            v-can="'FpExam2'"
            v-if="showIniciarButton(exam)"
            variant="primary"
            @click="handleStartAttendance(exam)"
          >
            Iniciar
          </b-button>
          <b-button
            v-can="'FpExam2'"
            v-else-if="showContinuarButton(exam)"
            variant="primary"
            @click="continueAttendance(exam)"
          >
            Continuar
          </b-button>
        </td>
        <td class="border-top"></td>
        <td class="border-top">
          <div class="actions">
            <b-button
              v-b-toggle="`collapse-${exam?.id}-${exam?.id}`"
              class="toggle-btn"
              @click="rotateIcon(exam?.id)"
            >
              <ChevronDown
                class="arrow"
                :class="rotatedList.includes(exam?.id) ? 'rotated' : ''"
              />
            </b-button>
          </div>
        </td>
      </tr>

      <tr class="collapsable-row">
        <td :colspan="14">
          <b-collapse :id="`collapse-${exam?.id}-${exam?.id}`" class="collapse">
            <SubExamsTable
              :exam="exam"
              @selected-exams="selectedExams"
              @aggregated="aggregated"
              @resetAttendance="resetAttendance"
            />
          </b-collapse>
        </td>
      </tr>
      </tbody>
    </b-table-simple>
    <NoFilterResults
      v-if="!examsList.length"
      message="Os filtros aplicados não geraram nenhum resultado"
    />
  </div>
</template>

<script>
import { getCurrentClinic, getCurrentUser } from '@/utils/localStorageManager'
import { isObject } from '@/utils/dataHelper'

export default {
  name: 'ExamsTable',
  components: {
    PatientInformation: () =>
      import('@/components/Overview/ScheduleTablePatientInformation'),
    Export: () => import('@/assets/icons/export.svg'),
    Ellipsis: () => import('@/components/General/Ellipsis'),
    SubExamsTable: () => import('@/components/Exam/SubExamsTable'),
    NoFilterResults: () => import('@/components/General/noFilterResults'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg')
  },
  props: {
    examsList: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    selected: [],
    clinic: getCurrentClinic(),
    user: getCurrentUser(),
    headers: [
      'Horário',
      'Paciente',
      'Profissional',
      'Sala',
      'Exames/Proced. pendentes',
      'Exames/Proced. realizados',
      ''
    ],
    invalidDate: '-000001-11-30T03:06:28.000000Z',
    rotatedList: [],
    examsStatusCount: {}
  }),
  methods: {
    rotateIcon(id) {
      this.rotatedList.includes(id)
        ? this.rotatedList.pop(id)
        : this.rotatedList.push(id)
    },
    goPatient(patientId) {
      const patientRoute = this.$router.resolve(
        `/pacientes/${patientId}`
      )
      window.open(patientRoute.href, '_blank')
    },
    /**
     * Add or Remove an item in the selected collection
     * @param {Array} value
     */
    selectedExams(value) {
      let hasItem = []
      value.forEach(item => {
        if (item.action === 'add') {
          hasItem = this.selected.filter(slctItem => {
            return slctItem.id === item.procedure.id
          })

          if (hasItem.length === 0) {
            this.selected.push(item.procedure)
          }
        } else if (item.action === 'delete') {
          this.selected = this.selected.filter(slctItem => {
            return slctItem.id !== item.procedure.id
          })
        }
      })
    },

    aggregated(examStatusCount) {
      this.examsStatusCount[examStatusCount[0]] = examStatusCount[1]
    },
    /**
     * Repasses the action to the parent
     */
    resetAttendance() {
      this.$emit('resetAttendance')
    },
    /**
     * Count appointment items by their status
     * @param {Object} appointment
     * @param {String} status
     * @returns {Number}
     */
    getAppointmentItemsCountingByStatus(appointment, status, type = 'EXAM') {
      return appointment.appointment_items.reduce((ac, it) => {
        if (
          it.attendance &&
          it.attendance.status === status &&
          it.item.type === type
        ) {
          ac++
        }
        if (
          !it.attendance &&
          (status === 'PENDING' || status === 'OPENED') &&
          it.item.type === type
        ) {
          ac++
        }

        return ac
      }, 0)
    },
    /**
     * "Workaround" to get finished exams status
     * @param {Object} exam
     * @returns {string}
     */
    getExamsStatus(exam) {
      const finished =
        this.getAppointmentItemsCountingByStatus(exam, 'FINISHED', 'EXAM') +
        this.getAppointmentItemsCountingByStatus(exam, 'FINISHED', 'PROCEDURE')

      return finished === exam.appointment_items.length
        ? 'ALL_EXAMS_FINISHED'
        : exam.status
    },
    /**
     * @param {Number} examsCount
     * @returns {String|Number}
     */
    textualExamCounting(examsCount) {
      return examsCount > 0
        ? examsCount > 1
          ? `${examsCount} Exames`
          : `${examsCount} Exame`
        : ''
    },
    textualProcedureCounting(proceduresCount) {
      return proceduresCount > 0
        ? proceduresCount > 1
          ? `${proceduresCount} Procedimentos`
          : `${proceduresCount} Procedimento`
        : ''
    },
    /**
     * Define whether a Iniciar Button is visible or not
     * @param {Object} exam
     * @returns {boolean}
     */
    showIniciarButton(exam) {
      return this.selected.some(
        proc => proc.appointment_id === exam.id && proc.attendance === null
      ) 
      && exam.status !== 'FINISHED'
      && exam.status !== 'CANCELLED'
    },
    /**
     * Define whether a Continuar Button is visible or not
     * @param {Object} exam
     * @returns {boolean}
     */
    showContinuarButton(exam) {
      const item = this.selected.find(item => item.appointment_id === exam.id)
      if (item) {
        return isObject(item.attendance)
      }

      return false
    },
    async handleStartAttendance(appointment) {
      const itemsToStart = this.selected.filter(
        item => item.appointment_id === appointment.id
      )

      if (itemsToStart[0].item.type === 'EXAM') {
        await this.createAttendanceExam(itemsToStart)
      }
      if (itemsToStart[0].item.type === 'PROCEDURE') {
        await this.createAttendanceProcedure(itemsToStart)
      }
    },
    async getDefaultAttendanceForm() {
      try {
        const response = await this.api.getForms(this.clinic.id, this.user.id)
        if (!response.data.length) return
        const form = response.data.some(f => f.default) ? response.data.find(f => f.default) : response.data[0]
        return form?.id
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    /**
     * Create a new Attendance by appointment id and redirects to Attendance View
     * @todo review this method to reflect the new data structure
     */
    async createAttendanceExam(examsToStart) {
      const isLoading = this.$loading.show()
      const data = {
        ids: examsToStart.map(item => item.id),
        appointmentId: examsToStart[0].appointment_id ?? null,
        professionalId: this.user?.id
      }
      const patientId = this.examsList.find(
        item => item.id === examsToStart[0].appointment_id
      ).patient_id
      this.api
        .createAttendanceExam(data)
        .then(res => {
          this.$router.push(`pacientes/${patientId}/exame/${res.data}`)
        })
        .catch(err => this.$toast.error(err.message))
        .finally(() => {
          isLoading.hide()
        })
    },
    async createAttendanceProcedure(proceduresToStart) {
      const isLoading = this.$loading.show()

      try {
        const formId = await this.getDefaultAttendanceForm()
        if (!formId) {
          this.$toast.error('Não foi possivel recuperar o tipo da consulta para o procedimento!')
          return
        }

        const patientId = this.examsList.find(
          exam => exam.id === proceduresToStart[0].appointment_id
        ).patient_id
        const data = {
          ids: proceduresToStart.map(item => item.id),
          appointment: proceduresToStart[0].appointment_id ?? null,
          form_id: formId,
          type: 'ATTENDANCE'
        }
        const response = await this.api.createAttendanceProcedure(data)
        this.$router.push(`/pacientes/${patientId}/consulta/${response.data}`)
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    /**
     * Go to attendance view
     * @param {Object} exam
     */
    continueAttendance(exam) {
      const item = this.selected.find(
        item => item.appointment_id === exam.id && isObject(item.attendance)
      )
      if (item) {
        let type = 'exame'
        if (item.item.type === 'PROCEDURE') {
          type = 'consulta'
        }
        const url = `pacientes/${exam.patient_id}/${type}/${item.attendance.id}`
        this.$router.push(url)
      } else {
        this.$toast.error(
          'Um atendimento não foi encontrado para os items selecionados'
        )
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.patient-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: initial !important;
  justify-content: space-between;
}

.avatar {
  width: 50px !important;
}

.exam-date {
  font-size: 11px;
}

.age {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.table th,
.table td {
  padding: 0.75rem 0;
  vertical-align: middle;
}

.tr-tbody {
  th {
    padding: 16px 8px;
  }

  .table {
    background-color: #ffffff !important;
  }

  .table th,
  .table td {
    padding: 0;
    vertical-align: middle;
    border-top: none;
  }

  td {
    padding: 16px 8px !important;
  }
}

.tr-header {
  th {
    padding: 16px 8px;
    border: none;
  }

  tr {
    background-color: #ffffff;
  }

  .table th,
  .table td {
    padding: 0;
    vertical-align: middle;
    border-top: none;
  }

  td {
    padding: 16px 8px !important;
  }
}

th,
td {
  text-align: left;
}

th {
  font-size: 14px;
  color: var(--dark-blue);
}

.td {
  padding: 0.75rem !important;
}

.procedure-column {
  max-width: 300px;
}

.clipboard-button {
  padding: 12px 16px;
  border-radius: 8px;
  background-color: var(--blue-100);
  border: none;

  &:hover {
    background-color: var(--neutral-300);
  }

  svg {
    stroke: var(--blue-500);
  }
}

.collapsable-row {
  td {
    padding: 0 !important;
    border: none !important;
  }
}

.toggle-btn {
  background: var(--blue-100);
  border: none;
  padding: 5px 8px !important;

  .icon {
    width: 20px;
    transition: all 500ms;
    stroke: var(--blue-500);
  }
}

.arrow {
  width: 14px;
  height: 14px;
}

.arrow {
  transition: rotate 1s;
}

.rotated {
  rotate: -180deg;
  transition: rotate 1s;
}

.actions {
  display: flex;

  .icon {
    width: 24px;
    height: 24px;
    margin-right: 6px;
    cursor: pointer;
  }
}

.export {
  margin-left: 10px;
  cursor: pointer;
}

.not-finished-exams {
  color: orangered !important;
}

.border-top {
  border-top: 2px solid #dee2e6;
}
</style>
